import { combineLatest, of, switchMap, map, distinctUntilChanged, iif, defer, tap } from 'rxjs';
import authentication from 'services/authentication';
import navigation, { link } from 'services/navigation';
import authorization from 'services/authorization';
import appLanguage from 'services/i18n/language';
import location from 'services/router/location';
import application from 'services/application';
import account from 'store/account';
import performer from 'store/performer';
import user from 'store/user';
import consent from 'store/consent';
import filterWhileNullish from 'utils/rxjs/filter-while-nullish';
import type { RouteMiddleware } from 'services/router/contracts';
import ff from 'services/feature-toggle';
import type { ApplicationRoute } from 'contracts';

const trailingGuard: RouteMiddleware = (routes) => {
  if (window.location.pathname.endsWith('/')) {
    const { name = '' } = routes.at(-1) ?? {};

    navigation[name]({ ...location.params() }, 'replace');

    return of(undefined);
  }

  return of(true);
};

const i18nGuard: RouteMiddleware = (routes) => {
  if (!location.params().lang) {
    const { name = '' } = routes.at(-1) ?? {};

    navigation[name]({ ...location.params(), lang: appLanguage.current });

    return of(undefined);
  }

  return of(true);
};

const authenticationGuard: RouteMiddleware = (routes) => {
  const hasProtectedRoute = routes.some((route) => authorization.has(route.name));
  const isLoginPage = link.login() === window.location.pathname;
  const isForcedLandingPage =
    link.dashboard() === window.location.pathname &&
    new URLSearchParams(window.location.search).get('forceShow') === '1';

  if (
    isLoginPage ||
    isForcedLandingPage ||
    !hasProtectedRoute ||
    authentication.isAuthenticated() ||
    ff.enabled('enableSimplifiedRegistration')
  )
    return of(true);

  navigation.login({ redirect: encodeURIComponent(window.location.href) });

  return of(false);
};

const userAccountGuard: RouteMiddleware = (routes) => {
  const hasProtectedRoute = routes.some((route) => authorization.has(route.name));

  if (!hasProtectedRoute) return of(true);

  const onAccountData$ = account.onChange$.pipe(filterWhileNullish());

  const registrationPending$ = onAccountData$.pipe(
    map((data) => data?.flags?.registrationPending),
    distinctUntilChanged(),
    map((pending) => {
      if (pending) {
        navigation.signup();

        return undefined;
      }

      return true;
    })
  );

  const msaAgreement$ = onAccountData$.pipe(
    map(() => user.isModelView()),
    distinctUntilChanged(),
    switchMap((onModelView) =>
      iif(
        () => onModelView,
        defer(() =>
          performer.onChange$.pipe(
            map((performerData) => user.isImpersonating(performerData.personIds)),
            switchMap((impersonating) =>
              iif(
                () => impersonating,
                of(false),
                consent.onChange$.pipe(map(({ expiredGracePeriod }) => expiredGracePeriod))
              )
            )
          )
        ),
        of(false)
      )
    ),
    map((expired) => {
      if (expired) {
        navigation.servicesAgreementUpdate();
        application.toggleNavigationLock(true);
      }

      return true;
    })
  );

  return combineLatest([registrationPending$, msaAgreement$]).pipe(map((result) => result.every(Boolean)));
};

const authorizationGuard: RouteMiddleware = (routes) => {
  const params = {
    ...location.params(),
    ...location.searchParams(),
    ...location.hashParams().reduce((acc, hash) => ({ ...acc, [hash]: true }), {}),
  };

  return combineLatest(
    routes.map((route) =>
      (authorization.getAuthorization$(route.name as keyof ApplicationRoute, params) ?? of(true)).pipe(
        filterWhileNullish()
      )
    )
  ).pipe(
    map((authorizations) => authorizations.every(Boolean)),
    tap((isAuthorized) => {
      if (!isAuthorized) {
        const routeName = user.isStudioView() ? 'models' : 'dashboard';

        navigation[routeName](undefined, 'replace');
      }
    })
  );
};

export { trailingGuard, i18nGuard, authenticationGuard, userAccountGuard, authorizationGuard };
